var scroll = 0, last_scroll_position = 0, last_change = 0;
var scrolled_up, scrolled;
var delta = 25;
var primary_navbar = jQuery('#primary-navbar');
var secondary_navbar = jQuery('#secondary-navbar');
var header = jQuery('#header');

jQuery(window).scroll(function() {
    scroll = jQuery(window).scrollTop();
    var diff = scroll - last_scroll_position;

    if (scroll > 200) {
        if (!scrolled) {
            primary_navbar.addClass('bg-white nav-small');
            secondary_navbar.addClass('bg-light');
            secondary_navbar.removeClass('bg-white');
            header.addClass('scrolled');
            primary_navbar.removeClass('with-nav');
            secondary_navbar.addClass('hidden');
            scrolled = true;
        }
        if (diff < 0) {
            if (!scrolled_up) {
                last_change = scroll;
                scrolled_up = true;
            }
            if (scrolled_up && ((last_change - scroll) > 100)) {
                secondary_navbar.removeClass('hidden');
                primary_navbar.addClass('with-nav');
            }
        } else {
            if (scrolled_up) {
                last_change = scroll;
                scrolled_up = false;
            }
            if (!scrolled_up && ((last_change - scroll) < -100)) {
                secondary_navbar.addClass('hidden');
                primary_navbar.removeClass('with-nav');
            }
        }
        last_scroll_position = scroll;
    } else {
        if (scrolled) {
            primary_navbar.removeClass('bg-white nav-small');
            secondary_navbar.addClass('bg-white');
            secondary_navbar.removeClass('bg-light');
            header.removeClass('scrolled');
            secondary_navbar.removeClass('hidden');
            primary_navbar.addClass('with-nav');
            scrolled = false;
        }
    }
});
